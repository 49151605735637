import React, { useState } from "react";
import { Modal, Button, Dropdown, DropdownButton } from "react-bootstrap";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism"; // Dracula theme for a modern look

// Assuming the projectExamples array is available here
const projectExamples = [
  {
    id: "Ticketing System",
    header1: "Express API on Node.js - Ticketing System",
    para1:
      "This example demonstrates an API route that allows users to create and manage support tickets. The API handles data such as user details, ticket description, and priority, and assigns default values for status and due date.",
    header2: "POST Request",
    para2:
      "The client sends a POST request to the `/tickets/new` endpoint, which creates a new ticket in the database with fields like `submitName`, `submitEmail`, `subject`, `description`, and `status`.",
    code: `
      router.post('/tickets/new', async (req, res) => {
        const created = new Date().toISOString();
        const { submitName, submitEmail, subject, description, status = 'Open' } = req.body;
        try {
          const pool = await poolPromise;
          const result = await pool.request()
            .input("submitName", sql.VarChar(50), submitName)
            .input("submitEmail", sql.VarChar(300), submitEmail)
            .input("subject", sql.VarChar(sql.MAX), subject)
            .input("description", sql.VarChar(sql.MAX), description)
            .input("status", sql.VarChar(50), status)
            .input("created", sql.DateTime, created)
            .execute('[dbo].[NewTicket]');
          res.send(result.recordsets);
        } catch (err) {
          res.status(500).send(err.message);
        }
      });
    `,
    header3: "How It Works",
    bullets: [
      "POST Request: The client sends a POST request with the ticket details to the `/tickets/new` endpoint.",
      "Data Handling: The API processes the ticket creation, setting default values like `status` and `created` timestamp.",
      "SQL Execution: The ticket details are stored in the database using a stored procedure or SQL statement.",
      "Error Handling: Any errors are caught, and the client receives an appropriate HTTP status code and error message.",
    ],
    header4: "Calling the API from React using Fetch",
    para3:
      "The following code shows how to send a request from a React component to the backend API using the ES6 Fetch API. This is done by making an asynchronous POST request to submit a new ticket.",
    fetchCode: `
      const submitNewTicket = async () => {
        const ticketData = {
          submitName: "User Name",
          submitEmail: "user@example.com",
          subject: "New ticket subject",
          description: "Detailed description of the issue or request.",
          status: "Open",
        };

        try {
          const response = await fetch('/tickets/new', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(ticketData),
          });
          const result = await response.json();
          console.log('Ticket created successfully:', result);
        } catch (error) {
          console.error('Error creating ticket:', error);
        }
      };

      // Call this function when submitting the ticket form
      submitNewTicket();
    `,
    finalNotes:
      "This API and client-side fetch call work together to allow users to submit new tickets in the system, improving task tracking and issue management.",
  },
  {
    id: "Manage Resources",
    header1: "Express API on Node.js - Managing Resources",
    para1:
      "This example demonstrates an API route that allows administrators to manage resources like facilitators and their skills. The API is designed to handle the addition, deletion, and update of facilitators and their respective skill sets.",
    header2: "POST Request",
    para2:
      "The client sends a POST request to the `/facilitators/new` endpoint, which creates a new facilitator in the database with fields like `name`, `skills`, and `onboarding` status.",
    code: `
      router.post('/facilitators/new', async (req, res) => {
        const { name, skills, onboarding } = req.body;
        try {
          const pool = await poolPromise;
          const result = await pool.request()
            .input("name", sql.VarChar(100), name)
            .input("skills", sql.VarChar(sql.MAX), JSON.stringify(skills))
            .input("onboarding", sql.Bit, onboarding)
            .execute('[dbo].[NewFacilitator]');
          res.send(result.recordsets);
        } catch (err) {
          res.status(500).send(err.message);
        }
      });
    `,
    header3: "How It Works",
    bullets: [
      "POST Request: The client sends a POST request with facilitator details to the `/facilitators/new` endpoint.",
      "Data Handling: The API processes the facilitator creation, allowing the user to specify skills and onboarding status.",
      "SQL Execution: The facilitator details are stored in the database using a stored procedure or SQL statement.",
      "Error Handling: Any errors are caught, and the client receives an appropriate HTTP status code and error message.",
    ],
    header4: "Calling the API from React using Fetch",
    para3:
      "The following code shows how to send a request from a React component to the backend API using the ES6 Fetch API. This is done by making an asynchronous POST request to submit a new facilitator.",
    fetchCode: `
      const submitNewFacilitator = async () => {
        const facilitatorData = {
          name: "New Facilitator",
          skills: Array(10).fill(false),  // Placeholder for skills
          onboarding: false,
        };

        try {
          const response = await fetch('/facilitators/new', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(facilitatorData),
          });
          const result = await response.json();
          console.log('Facilitator created successfully:', result);
        } catch (error) {
          console.error('Error creating facilitator:', error);
        }
      };

      // Call this function when submitting the facilitator form
      submitNewFacilitator();
    `,
    finalNotes:
      "This API and client-side fetch call work together to allow administrators to manage resources efficiently by adding new facilitators with specific skill sets.",
  },
  {
    id: "Action Required Sidebar - Task Management",
    header1: "Express API on Node.js - Action Required Task Management",
    para1:
      "This example demonstrates an API route that allows the creation, updating, and movement of tasks in an 'Action Required' sidebar. Users can add new tasks, move them between sections, and update their status.",
    header2: "POST Request",
    para2:
      "The client sends a POST request to the `/tasks/new` endpoint, which creates a new task in the database with details such as `submitName`, `taskText`, `status`, and `section`.",
    code: `
      router.post('/tasks/new', async (req, res) => {
        const created = new Date().toISOString();
        const { submitName, taskText, section, status } = req.body;
        try {
          const pool = await poolPromise;
          const result = await pool.request()
            .input("submitName", sql.VarChar(50), submitName)
            .input("taskText", sql.VarChar(sql.MAX), taskText)
            .input("section", sql.VarChar(50), section)
            .input("status", sql.VarChar(50), status)
            .input("created", sql.DateTime, created)
            .execute('[dbo].[NewTask]');
          res.send(result.recordsets);
        } catch (err) {
          res.status(500).send(err.message);
        }
      });
    `,
    header3: "How It Works",
    bullets: [
      "POST Request: The client sends a POST request with task details such as submitName, taskText, and section to the `/tasks/new` endpoint.",
      "Data Handling: The API processes the task creation and assigns default values like status and created timestamp.",
      "SQL Execution: The task details are stored in the database through a stored procedure or SQL statement.",
      "Error Handling: Any errors are caught, and the client receives an appropriate HTTP status code and error message.",
    ],
    header4: "Calling the API from React using Fetch",
    para3:
      "The following code shows how to send a request from a React component to the backend API using the ES6 Fetch API. This is done by making an asynchronous POST request to submit a new task.",
    fetchCode: `
      const submitNewTask = async () => {
        const taskData = {
          submitName: "User",
          taskText: "New task to be added",
          section: "inProgress",
          status: "Open",
        };

        try {
          const response = await fetch('/tasks/new', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(taskData),
          });
          const result = await response.json();
          console.log('Task created successfully:', result);
        } catch (error) {
          console.error('Error creating task:', error);
        }
      };

      // Call this function in the component where task creation happens, e.g., onSubmit
      submitNewTask();
    `,
    finalNotes:
      "This API and client-side fetch call work together to allow dynamic task creation in the 'Action Required' sidebar. The task management feature enables users to track and move tasks through different sections in the system, ensuring smooth collaboration and workflow management.",
  },
];

const NodeProjectModal = ({ show, onHide }) => {
  const [selectedProject, setSelectedProject] = useState(projectExamples[0]);

  const handleSelectProject = (id) => {
    const project = projectExamples.find((proj) => proj.id === id);
    setSelectedProject(project);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      centered
      className="node-project-modal"
    >
      <Modal.Header closeButton>
        <DropdownButton
          id="dropdown-basic-button"
          title={selectedProject.header1}
          onSelect={handleSelectProject}
        >
          {projectExamples.map((proj) => (
            <Dropdown.Item key={proj.id} eventKey={proj.id}>
              {proj.header1}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </Modal.Header>
      <Modal.Body>
        <h4>{selectedProject.header1}</h4>
        <p>{selectedProject.para1}</p>

        <h5>{selectedProject.header2}</h5>
        <p>{selectedProject.para2}</p>

        <SyntaxHighlighter language="javascript" style={dracula}>
          {selectedProject.code}
        </SyntaxHighlighter>

        <h5>{selectedProject.header3}</h5>
        <ul>
          {selectedProject.bullets.map((bullet, index) => (
            <li key={index}>{bullet}</li>
          ))}
        </ul>

        <h5>{selectedProject.header4}</h5>
        <p>{selectedProject.para3}</p>

        <SyntaxHighlighter language="javascript" style={dracula}>
          {selectedProject.fetchCode}
        </SyntaxHighlighter>

        <p>{selectedProject.finalNotes}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close Project
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NodeProjectModal;
