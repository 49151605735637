import React, { useState, useEffect } from "react";
import { Button, Offcanvas, Form } from "react-bootstrap";

const ProfileSidebar = ({ showSidebar, closeSidebar }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [phoneIsValid, setPhoneIsValid] = useState(true); // Track phone validity

  // Load profile data from localStorage on mount
  useEffect(() => {
    const savedName = localStorage.getItem("name");
    const savedEmail = localStorage.getItem("email");
    const savedPhone = localStorage.getItem("phone");
    const savedAddress = localStorage.getItem("address");
    const savedNotifications =
      JSON.parse(localStorage.getItem("notifications")) || [];

    if (savedName) setName(savedName);
    if (savedEmail) setEmail(savedEmail);
    if (savedPhone) setPhone(savedPhone);
    if (savedAddress) setAddress(savedAddress);
    setNotifications(savedNotifications);
  }, []);

  const saveProfile = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /\d{9}/; // Basic validation for 10-digit phone numbers

    if (!emailRegex.test(email) && email.length > 1) {
      setEmailIsValid(false);
      return;
    }

    setEmailIsValid(true);

    if (!phoneRegex.test(phone) && phone.length > 1) {
      setPhoneIsValid(false);
      return;
    }

    setPhoneIsValid(true);

    localStorage.setItem("name", name);
    localStorage.setItem("email", email);
    localStorage.setItem("phone", phone);
    localStorage.setItem("address", address);
    localStorage.setItem("notifications", JSON.stringify(notifications));
    closeSidebar();
  };
  const deleteProfile = () => {
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("phone");
    localStorage.removeItem("address");
    localStorage.removeItem("notifications");

    setName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setNotifications([]);
  };
  return (
    <Offcanvas
      show={showSidebar}
      onHide={closeSidebar}
      placement="end"
      style={{ width: "400px" }}
      className="smooth-offcanvas"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Profile</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <p style={{ fontSize: "0.75rem", color: "#555" }}>
          No data or information is collected. All data is stored in your
          temporary browser cache for demonstration purposes only.
        </p>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Name:</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email:</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                borderColor: emailIsValid ? "" : "red", // Turn red if email is invalid
              }}
            />
            {!emailIsValid && (
              <Form.Text className="text-danger">
                Please enter a valid email.
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Phone:</Form.Label>
            <Form.Control
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              style={{
                borderColor: phoneIsValid ? "" : "red", // Turn red if phone is invalid
              }}
            />
            {!phoneIsValid && (
              <Form.Text className="text-danger">
                Please enter a valid 9-digit phone number.
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Address:</Form.Label>
            <Form.Control
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Preferred Notifications:</Form.Label>
            <Form.Check
              type="checkbox"
              label="Email (Does not send notifications)"
              className="custom-checkbox"
              checked={notifications.includes("email")}
              onChange={() =>
                setNotifications((prev) =>
                  prev.includes("email")
                    ? prev.filter((n) => n !== "email")
                    : [...prev, "email"]
                )
              }
            />
            <Form.Check
              type="checkbox"
              label="SMS (Does not send notifications)"
              className="custom-checkbox"
              checked={notifications.includes("sms")}
              onChange={() =>
                setNotifications((prev) =>
                  prev.includes("sms")
                    ? prev.filter((n) => n !== "sms")
                    : [...prev, "sms"]
                )
              }
            />
          </Form.Group>
          <div className="d-flex flex-column">
            <Button
              onClick={saveProfile}
              className="btn primary"
              style={{ marginTop: "10px", fontSize: "0.8em" }}
            >
              Save Profile
            </Button>

            <Button
              onClick={deleteProfile}
              variant="danger"
              style={{ marginTop: "80px", fontSize: "0.8em" }}
            >
              Delete Temporary Data
            </Button>
          </div>
        </Form>
        <p style={{ fontSize: "0.75rem", color: "#555", marginTop: "5%" }}>
          No data or information is collected. All data is stored in your
          temporary browser cache for demonstration purposes only.
        </p>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ProfileSidebar;
