import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { Modal, Button } from "react-bootstrap"; // Import React-Bootstrap components
import "bootstrap/dist/css/bootstrap.min.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const isMobile = window.innerWidth <= 768;

const Resume = () => {
  const [numPages, setNumPages] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleModalOpen = () => {
    setIsModalOpen(true); // Open modal
  };

  const handleModalClose = () => {
    setIsModalOpen(false); // Close modal
  };

  return (
    <div className="projects-page">
      {/* Navigation Buttons */}
      <div className="project-buttons">
        <Link to="/Home" className="btn">
          Home
        </Link>
        <Link to="/Projects" className="btn">
          Projects
        </Link>
      </div>

      <h1>Resume</h1>

      {/* PDF Container - Click to Zoom */}
      <div
        className="pdf-container"
        onClick={!isMobile ? handleModalOpen : null}
      >
        <Document
          file="/JeremiahMountainWebsite.pdf"
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          ))}
        </Document>
      </div>

      {/* React-Bootstrap Modal */}
      <Modal show={isModalOpen} onHide={handleModalClose} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Render the PDF inside the modal */}
          <div style={{ maxHeight: "80vh", overflowY: "auto", width: "100%" }}>
            <Document
              file="/JeremiahMountain.pdf"
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`modal_page_${index + 1}`}
                  pageNumber={index + 1}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  scale={1.7}
                />
              ))}
            </Document>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Resume;
