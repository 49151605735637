import React, { useState } from "react";
import { Modal, Button, Form, Table, Spinner } from "react-bootstrap";

// Sample users data
const initialUsers = [
  {
    id: 1,
    name: "John Doe",
    email: "john.doe@example.com",
    employeeNumber: "88998727",
    location: "New York",
    timeZone: "EST",
    userId: "john123",
    permissions: {
      administrator: false,
      manager: true,
      trainer: true,
      trainee: true,
    },
  },
  {
    id: 2,
    name: "Jane Smith",
    email: "jane.smith@example.com",
    employeeNumber: "88998726",
    location: "Los Angeles",
    timeZone: "PST",
    userId: "jane456",
    permissions: {
      administrator: false,
      manager: false,
      trainer: true,
      trainee: true,
    },
  },
];

const UserPermissionsModal = ({ show, onHide }) => {
  const [users, setUsers] = useState(initialUsers);
  const [newUserName, setNewUserName] = useState("");
  const [showAddUserField, setShowAddUserField] = useState(false);
  const [loadingPermissions, setLoadingPermissions] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);

  // Toggle permission with spinner simulation
  const handlePermissionChange = (userId, permission) => {
    setLoadingPermissions((prev) => ({ ...prev, [userId]: permission }));

    setTimeout(() => {
      setUsers((prevUsers) =>
        prevUsers.map((user) => {
          if (user.id === userId) {
            // Apply cascading logic for Administrator and Trainer
            const updatedPermissions = {
              ...user.permissions,
              [permission]: !user.permissions[permission],
            };

            if (
              permission === "administrator" &&
              updatedPermissions.administrator
            ) {
              // If Administrator is selected, select all other permissions and disable lower levels

              updatedPermissions.manager = updatedPermissions.administrator;
              updatedPermissions.trainer = updatedPermissions.administrator;
              updatedPermissions.trainee = updatedPermissions.administrator;
            } else if (permission === "manager" && updatedPermissions.manager) {
              // If Administrator is selected, select all other permissions and disable lower levels

              updatedPermissions.trainer = updatedPermissions.manager;
              updatedPermissions.trainee = updatedPermissions.manager;
            } else if (permission === "trainer" && updatedPermissions.trainer) {
              // If Trainer is selected, Trainee should be selected too
              updatedPermissions.trainee = updatedPermissions.trainer;
            }

            return {
              ...user,
              permissions: updatedPermissions,
            };
          }
          return user;
        })
      );
      setLoadingPermissions((prev) => ({ ...prev, [userId]: null }));
    }, 250); // Simulate 2 second save delay
  };

  // Delete a user
  const handleDeleteUser = (userId) => {
    setUsers(users.filter((user) => user.id !== userId));
  };

  // Add a new user
  const handleAddUser = () => {
    if (newUserName.trim()) {
      const newUser = {
        id: Math.random(),
        name: newUserName,
        email: `${newUserName.toLowerCase().replace(" ", ".")}@example.com`,
        employeeNumber: `${Math.floor(10000000 + Math.random() * 90000000)}`,
        location: "Unknown",
        timeZone: "CST",
        userId: `${newUserName.toLowerCase().replace(" ", "")}`,
        permissions: {
          administrator: false,
          manager: false,
          trainer: false,
          trainee: false,
        },
      };
      setUsers([...users, newUser]);
      setNewUserName("");
      setShowAddUserField(false);
    }
  };

  // Open modal for detailed user info
  const openUserModal = (user) => {
    setSelectedUser(user);
  };

  // Close user details modal
  const closeUserModal = () => {
    setSelectedUser(null);
  };

  return (
    <>
      <Modal show={show} onHide={onHide} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>User Permissions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>User</th>
                <th>Administrator</th>
                <th>Manager</th>
                <th>Trainer</th>
                <th>Trainee</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td data-label="Name">
                    <span
                      style={{
                        color: "#FF007F",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => openUserModal(user)}
                    >
                      {user.name}
                    </span>
                  </td>
                  {["administrator", "manager", "trainer", "trainee"].map(
                    (permission) => (
                      <td
                        key={permission}
                        data-label={
                          permission.charAt(0).toUpperCase() +
                          permission.slice(1)
                        }
                      >
                        {loadingPermissions[user.id] === permission ? (
                          <Spinner
                            animation="border"
                            size="sm"
                            style={{ color: "#FF007F" }}
                          />
                        ) : (
                          <Form.Check
                            type="switch"
                            checked={user.permissions[permission]}
                            onChange={() =>
                              handlePermissionChange(user.id, permission)
                            }
                            disabled={
                              (user.permissions.administrator &&
                                permission !== "administrator") ||
                              (user.permissions.manager &&
                                permission !== "manager" &&
                                permission !== "administrator") ||
                              (user.permissions.trainer &&
                                permission === "trainee")
                            } // Disable permissions below Administrator and Trainee if selected
                          />
                        )}
                      </td>
                    )
                  )}
                  <td data-label="Delete User">
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDeleteUser(user.id)}
                    >
                      X
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* Add User Section */}
          {!showAddUserField && (
            <Button variant="primary" onClick={() => setShowAddUserField(true)}>
              Add User
            </Button>
          )}
          {showAddUserField && (
            <div className="mt-3">
              <Form.Control
                type="text"
                placeholder="Enter new user name"
                value={newUserName}
                onChange={(e) => setNewUserName(e.target.value)}
              />
              <Button
                variant="success"
                className="mt-2"
                onClick={handleAddUser}
              >
                Add
              </Button>{" "}
              <Button
                variant="secondary"
                className="mt-2"
                onClick={() => setShowAddUserField(false)}
              >
                Cancel
              </Button>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close Project
          </Button>
        </Modal.Footer>
      </Modal>

      {/* User Details Modal */}
      {selectedUser && (
        <Modal show={true} centered onHide={closeUserModal}>
          <Modal.Header closeButton>
            <Modal.Title>User Details: {selectedUser.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <strong>Username:</strong> {selectedUser.userId}
            </p>
            <p>
              <strong>Email:</strong> {selectedUser.email}
            </p>
            <p>
              <strong>Employee Number:</strong> {selectedUser.employeeNumber}
            </p>
            <p>
              <strong>Location:</strong> {selectedUser.location}
            </p>
            <p>
              <strong>Time Zone:</strong> {selectedUser.timeZone}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeUserModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default UserPermissionsModal;
