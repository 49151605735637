import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { loadFull } from "tsparticles";
import { Particles } from "react-tsparticles";
import ProfilePic from "../assets/profilePic.jpg";
import { Link } from "react-router-dom";
import ProfileSidebar from "./ProfileSidebar";
import { EnvelopeCheck, PersonFill, PhoneVibrate } from "react-bootstrap-icons";

const isMobile = window.innerWidth <= 768;

const Home = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [currentUser, setCurrentUser] = useState(localStorage.getItem("name") ?? "User");

  // useEffect(() => {
  //   const savedNotifications =
  //     JSON.parse(localStorage.getItem("notifications")) || [];
  //   const savedName = localStorage.getItem("name");
  //   setNotifications(savedNotifications);
  //   setCurrentUser(savedName);
  // }, []);
  useEffect(() => {
    const loadFromLocalStorage = () => {
      const savedNotifications = JSON.parse(localStorage.getItem("notifications")) || [];
      const savedName = localStorage.getItem("name");
      setNotifications(savedNotifications);
      setCurrentUser(savedName ? savedName : "User");
    };

    // Load initially when the component mounts
    loadFromLocalStorage();

    // Listen for storage changes in other tabs
    const handleStorageChange = (e) => {
      if (e.key === "notifications" || e.key === "name") {
        loadFromLocalStorage();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // To handle local changes in the same tab
    const originalSetItem = localStorage.setItem;
    localStorage.setItem = function (key, value) {
      originalSetItem.apply(this, arguments); // Call the original setItem method
      const event = new Event("localstorage-update");
      window.dispatchEvent(event);
    };

    // Listen for custom event to update state for local changes
    const handleLocalChange = () => {
      loadFromLocalStorage();
    };

    window.addEventListener("localstorage-update", handleLocalChange);

    // Cleanup listeners when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("localstorage-update", handleLocalChange);
    };
  }, []);

  // updateSo(() => {
  //   localStorage.setItem("notifications", JSON.stringify(notifications));
  // }, [notifications]);

  const removePhone = () => {
    let updated = notifications.filter((g) => !g.includes("sms"));
    localStorage.setItem("notifications", JSON.stringify(updated));
  };

  const removeEmail = () => {
    let updated = notifications.filter((g) => !g.includes("email"));
    localStorage.setItem("notifications", JSON.stringify(updated));
  };

  const particlesInit = async (main) => {
    await loadFull(main);
  };

  const particlesOptions = {
    particles: {
      number: {
        value: 100,
        density: {
          enable: true,
          value_area: 800,
        },
      },
      color: {
        value: "#FF007F",
      },
      opacity: {
        value: 0.5,
        random: true,
      },
      size: {
        value: 8,
        random: true,
      },
      line_linked: {
        enable: true,
        distance: 155,
        color: "#ffffff",
        opacity: 0.2,
        width: 1,
      },
      move: {
        enable: true,
        speed: 1,
      },
    },
  };

  return (
    <>
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={particlesOptions}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: "-1",
        }}
      />
      {/* Header with Profile Button */}
      <header className="header">
        <div
          className="d-flex flex-row flex-wrap justify-content-end align-items-center"
          style={{ marginRight: "10px", width: "100%", marginTop: "20px" }}
        >
          <h6 style={{ marginTop: "15px", fontSize: "0.8em" }}>{`Welcome, ${currentUser ?? "User"}`}</h6>
          <Button
            className="btn primary"
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
            onClick={() => setShowSidebar(true)}
          >
            <PersonFill
              size={20}
              style={{ color: "#FF007F" }}
            />
          </Button>

          {/* Grouping the | and notification icons in a flex container to keep them together */}
          {(notifications.includes("sms") || notifications.includes("email")) && (
            <div className="d-flex align-items-center">
              {/* <div style={{ marginRight: "1%" }}>{"|"}</div> */}
              <h6
                style={{
                  marginTop: "15px",
                  color: "#FF007F",
                  fontSize: "0.8em",
                }}
              >
                Notifications:{" "}
              </h6>

              {notifications.includes("sms") && (
                <Button
                  className="btn primary"
                  style={{
                    backgroundColor: "transparent",
                    borderColor: "transparent",
                  }}
                  onClick={() => {
                    setNotifications((prev) => (prev.includes("sms") ? prev.filter((n) => n !== "sms") : [...prev, "sms"]));
                    removePhone();
                  }}
                >
                  <PhoneVibrate
                    size={20}
                    style={{ color: "#FF007F" }}
                  />
                </Button>
              )}
              {notifications.includes("email") && (
                <Button
                  className="btn primary"
                  style={{
                    backgroundColor: "transparent",
                    borderColor: "transparent",
                  }}
                  onClick={() => {
                    setNotifications((prev) => (prev.includes("email") ? prev.filter((n) => n !== "email") : [...prev, "email"]));
                    removeEmail();
                  }}
                >
                  <EnvelopeCheck
                    size={20}
                    style={{ color: "#FF007F" }}
                  />
                </Button>
              )}
            </div>
          )}
        </div>
      </header>

      {/* Profile Sidebar */}
      {showSidebar && (
        <ProfileSidebar
          showSidebar={showSidebar}
          closeSidebar={() => setShowSidebar(false)}
        />
      )}

      <section
        className="hero"
        style={isMobile ? { marginTop: "145px" } : { marginTop: "0px" }}
      >
        <div className="hero-content">
          <h1>Building Impactful Software Solutions</h1>
          <p>{`I am a Senior Developer with extensive expertise in React, Node.js, and SQL, focused on building scalable, user-centered solutions. With a proven ability to lead software teams, I deliver high-quality results on complex projects efficiently and effectively.`}</p>

          <div className="hero-buttons">
            <Link
              to="/Projects"
              className="btn primary"
            >
              Projects
            </Link>
            {/* <Link to="/Resume" className="btn primary">
              Resume
            </Link> */}
          </div>
        </div>
        <div className="hero-image">
          <img
            src={ProfilePic}
            alt="Jeremiah Mountain"
          />
        </div>
      </section>
    </>
  );
};

export default Home;
