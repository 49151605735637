import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./components/Home";
import Projects from "./components/Projects";
import Resume from "./components/Resume";
import "./App.css";
import "./Animations.css";
import "./HoverEffects.css";

function App() {
  return (
    <div className="App custom-scrollbar">
      {/* <div className="bubble-container">
        <div className="bubble" style={{ opacity: "0.1" }}></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
      </div> */}

      <Router>
        <Routes>
          <Route path="/Home" element={<Home />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/Resume" element={<Resume />} />
          <Route path="*" element={<Navigate to="/Home" replace />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
