// Projects.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import TicketModal from "./TicketModal";
import TrainingModal from "./TrainingModal";
import NodeProjectModal from "./NodeProjectModal";
import AttendanceTool from "./AttendanceTool";
import CryptoTrackerModal from "./CryptoTrackerModal";
import UserPermissionsModal from "./UserPermissionsModal";

const Projects = () => {
  const [showTicketModal, setShowTicketModal] = useState(false); // Modal state for Ticketing App
  const [showTrainingModal, setShowTrainingModal] = useState(false); // Modal state for Comprehensive Training
  const [showExpressModal, setShowExpressModal] = useState(false); //handleAttendanceModalOpen
  const [showAttendanceModal, setShowAttendanceModal] = useState(false); // handleCryptoModalOpen
  const [showCryptoModal, setShowCryptoModal] = useState(false);
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);

  const handleAttendanceModalOpen = () => {
    setShowAttendanceModal(true);
  };
  const handleCryptoModalOpen = () => {
    setShowCryptoModal(true);
  };
  const handlePermissionsModalOpen = () => {
    setShowPermissionsModal(true);
  };
  // Open the ticket modal
  const handleTicketModalOpen = () => {
    setShowTicketModal(true);
  };

  // Open the Comprehensive Training modal
  const handleTrainingModalOpen = () => {
    setShowTrainingModal(true);
  };

  const handleExpressModalOpen = () => {
    setShowExpressModal(true);
  };

  // Close all modals
  const handleModalClose = () => {
    setShowTicketModal(false);
    setShowTrainingModal(false);
    setShowExpressModal(false);
    setShowAttendanceModal(false);
    setShowCryptoModal(false);
    setShowPermissionsModal(false);
  };

  return (
    <div className="projects-page custom-scrollbar themeTrickle">
      {/* Navigation Buttons */}
      <div className="project-buttons">
        <Link
          to="/Home"
          className="btn"
        >
          Home
        </Link>
        {/* <Link to="/Resume" className="btn">
          Resume
        </Link> */}
      </div>

      <h1 style={{ marginBottom: "10%" }}>Projects</h1>

      {/* Ticketing Application Link */}
      <div className="project-link">
        <h3
          onClick={handleTicketModalOpen}
          className="project-title"
        >
          Ticketing Application
        </h3>
        <p>Ticketing System Example</p>

        <a
          href="https://github.com/Noimia/React-Ticketing-Example" // Placeholder GitHub URL
          target="_blank"
          rel="noopener noreferrer"
          className="github-link"
        >
          View the code on GitHub
        </a>
      </div>

      {/* Project Link for Comprehensive Training System */}
      <div
        className="project-link"
        onClick={handleTrainingModalOpen}
      >
        <h3>Training System</h3>
        <p>Training System Example</p>
        <a
          href="https://github.com/Noimia/React-Training-System-Example" // Placeholder GitHub URL
          target="_blank"
          rel="noopener noreferrer"
          className="github-link"
        >
          View the code on GitHub
        </a>
      </div>
      <div
        className="project-link"
        onClick={handleExpressModalOpen}
      >
        <h3>API Examples</h3>
        <p>Node.js - Express Routing to SQL</p>
        <a
          href="https://github.com/Noimia/React-Node-Express-API-Examples" // Placeholder GitHub URL
          target="_blank"
          rel="noopener noreferrer"
          className="github-link"
        >
          View the code on GitHub
        </a>
      </div>
      <div
        className="project-link"
        onClick={handleAttendanceModalOpen}
      >
        <h3>Attendance Tool</h3>
        <p>Attendance System Example</p>
        {/* <a
          href="https://github.com/Noimia/React-Node-Express-API-Examples" // Placeholder GitHub URL
          target="_blank"
          rel="noopener noreferrer"
          className="github-link"
        >
          View the code on GitHub
        </a> */}
      </div>
      <div
        className="project-link"
        onClick={handleCryptoModalOpen}
      >
        <h3>Crypto Tracking</h3>
        <p>Crypto Tracking Example</p>
        {/* <a
          href="https://github.com/Noimia/React-Node-Express-API-Examples" // Placeholder GitHub URL
          target="_blank"
          rel="noopener noreferrer"
          className="github-link"
        >
          View the code on GitHub
        </a> */}
      </div>
      <div
        className="project-link"
        onClick={handlePermissionsModalOpen}
      >
        <h3>Permissions Tool</h3>
        <p>Permissions System Example</p>
        {/* <a
          href="https://github.com/Noimia/React-Node-Express-API-Examples" // Placeholder GitHub URL
          target="_blank"
          rel="noopener noreferrer"
          className="github-link"
        >
          View the code on GitHub
        </a> */}
      </div>
      {/* Project Link for High-Performance Microservices */}
      {/* <div
        className="project-link"
        onClick={() => console.log("High-Performance Microservices")}
      >
        <h3>High-Performance Microservices</h3>
        <p>Scalable microservices architecture</p>
      </div> */}

      {/* Ticket Modal */}
      <TicketModal
        show={showTicketModal}
        onHide={handleModalClose}
      />

      {/* Comprehensive Training Modal */}
      <TrainingModal
        show={showTrainingModal}
        onHide={handleModalClose}
      />

      <NodeProjectModal
        show={showExpressModal}
        onHide={handleModalClose}
      />

      <AttendanceTool
        show={showAttendanceModal}
        onHide={handleModalClose}
      />

      <CryptoTrackerModal
        show={showCryptoModal}
        onHide={handleModalClose}
      />

      <UserPermissionsModal
        show={showPermissionsModal}
        onHide={handleModalClose}
      />
    </div>
  );
};

export default Projects;
