import React, { useState } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";

const initialUsers = [
  {
    id: 1,
    name: "User 1",
    attendance: {},
    checkInTime: null,
    endTime: null,
    extraMinutes: 0,
    dailyNote: "",
    minutesLate: 0,
  },
  {
    id: 2,
    name: "User 2",
    attendance: {},
    checkInTime: null,
    endTime: null,
    extraMinutes: 0,
    dailyNote: "",
    minutesLate: 0,
  },
];

const AttendanceTool = ({ show, onHide }) => {
  const [users, setUsers] = useState(initialUsers);
  const [newUserName, setNewUserName] = useState("");
  const [showAddUserField, setShowAddUserField] = useState(false);
  const [currentDay, setCurrentDay] = useState(new Date());
  const [dailyStartTime, setDailyStartTime] = useState({ hour: 8, minute: 0 });
  const [timeZone, setTimeZone] = useState("CST");
  const [trainingLength, setTrainingLength] = useState(480); // 8 hours default
  const [view, setView] = useState("daily"); // 'daily', 'weekly', or 'monthly'
  const [addingExtraTime, setAddingExtraTime] = useState(null); // Track extra time being added
  const [extraTimeValue, setExtraTimeValue] = useState(0); // Extra time input
  const [userToDelete, setUserToDelete] = useState(null);

  // Helper function to format dates
  const formatDate = (date) => date.toISOString().split("T")[0];

  // Add new user
  const handleAddUser = () => {
    if (!newUserName.trim()) return;
    const newUser = {
      id: Math.random(),
      name: newUserName,
      attendance: {},
      checkInTime: null,
      endTime: null,
      extraMinutes: 0,
      dailyNote: "",
      minutesLate: 0,
    };
    setUsers([...users, newUser]);
    setNewUserName("");
    setShowAddUserField(false);
  };

  // Delete user
  const handleDeleteUser = () => {
    setUsers(users.filter((user) => user.id !== userToDelete));
    setUserToDelete(null); // Close the modal after deletion
  };

  // Handle paging through days/weeks/months
  const handlePaging = (direction) => {
    const newDate = new Date(currentDay);
    if (view === "daily") {
      newDate.setDate(newDate.getDate() + direction); // Day change
    } else if (view === "weekly") {
      newDate.setDate(newDate.getDate() + direction * 7); // Week change
    } else if (view === "monthly") {
      newDate.setMonth(newDate.getMonth() + direction); // Month change
    }
    setCurrentDay(newDate);
  };

  // Handle view change (daily, weekly, monthly)
  const handleViewChange = (newView) => setView(newView);

  // Handle check-in (On-Time, Late, Absent)
  const handleCheckIn = (userId, status) => {
    const startDate = new Date(currentDay);
    startDate.setHours(dailyStartTime.hour, dailyStartTime.minute, 0, 0);

    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId
          ? {
              ...user,
              attendance: {
                ...user.attendance,
                [formatDate(currentDay)]: {
                  status,
                  checkInTime: status === "absent" ? null : startDate,
                  endTime: status === "absent" ? startDate : null,
                  extraMinutes: 0,
                  minutesLate: status === "late" ? null : 0, // Leave null for late until filled
                },
              },
            }
          : user
      )
    );

    if (status === "absent") {
      handleEndDay(userId, 0, startDate); // Automatically end the day with 0 hours if Absent
    }
  };

  const handleLateTimeChange = (userId, minutesLate) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId
          ? {
              ...user,
              attendance: {
                ...user.attendance,
                [formatDate(currentDay)]: {
                  ...user.attendance[formatDate(currentDay)],
                  minutesLate: minutesLate,
                },
              },
            }
          : user
      )
    );
  };

  // Handle end day (Check-out)
  const handleEndDay = (userId, trainingLengthOverride, startDate) => {
    const currentAttendance = users.find((user) => user.id === userId)
      .attendance[formatDate(currentDay)];
    if (!currentAttendance) return;

    const now = new Date();
    const endTime = new Date(
      currentAttendance.checkInTime.getTime() +
        (trainingLengthOverride || trainingLength) * 60000
    ); // Calculate the end time
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId
          ? {
              ...user,
              attendance: {
                ...user.attendance,
                [formatDate(currentDay)]: {
                  ...currentAttendance,
                  endTime:
                    trainingLengthOverride === 0
                      ? startDate
                      : now < endTime
                      ? now
                      : endTime, // Don't exceed training length
                },
              },
            }
          : user
      )
    );
  };

  // Add extra time to the user's worked time
  const handleAddTime = (userId) => {
    const currentAttendance = users.find((user) => user.id === userId)
      .attendance[formatDate(currentDay)];
    if (!currentAttendance) return;
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId
          ? {
              ...user,
              attendance: {
                ...user.attendance,
                [formatDate(currentDay)]: {
                  ...currentAttendance,
                  extraMinutes: currentAttendance.extraMinutes + extraTimeValue,
                },
              },
            }
          : user
      )
    );
    setAddingExtraTime(null); // Close extra time input
    setExtraTimeValue(0); // Reset value
  };

  const calculateTotalTime = (user, type = "daily") => {
    const formatDate = (date) => date.toISOString().split("T")[0];

    const getWeekRange = (date) => {
      const dayOfWeek = date.getDay(); // Get the day of the week (0=Sunday, 6=Saturday)
      const startOfWeek = new Date(date);
      startOfWeek.setDate(date.getDate() - dayOfWeek); // Move back to Sunday
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6); // Move forward to Saturday
      return { startOfWeek, endOfWeek };
    };

    const getMonthRange = (date) => {
      const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1); // First day of month
      const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0); // Last day of month
      return { startOfMonth, endOfMonth };
    };

    const calculateWorkedMinutes = (attendance) => {
      let endTime = new Date(
        attendance.checkInTime.getTime() + trainingLength * 60000
      );
      if (attendance.endTime <= endTime) {
        endTime = attendance.endTime;
      }
      return (
        Math.floor((endTime - attendance.checkInTime) / 60000) +
        attendance.extraMinutes -
        attendance.minutesLate
      );
    };

    if (type === "daily") {
      const currentAttendance = user.attendance[formatDate(currentDay)];
      if (
        !currentAttendance ||
        !currentAttendance.checkInTime ||
        !currentAttendance.endTime
      ) {
        return "-";
      }
      const workedMinutes = calculateWorkedMinutes(currentAttendance);
      const hours = Math.floor(workedMinutes / 60);
      const minutes = workedMinutes % 60;
      return `${hours}h ${minutes}m`;
    } else if (type === "weekly") {
      // Calculate weekly range for the current day (Sunday to Saturday)
      const { startOfWeek, endOfWeek } = getWeekRange(currentDay);

      const weekAttendance = Object.entries(user.attendance).filter(
        ([date]) => new Date(date) >= startOfWeek && new Date(date) <= endOfWeek
      );

      const totalWorkedMinutes = weekAttendance.reduce(
        (total, [, attendance]) =>
          total +
          (attendance.checkInTime && attendance.endTime
            ? calculateWorkedMinutes(attendance)
            : 0),
        0
      );

      const hours = Math.floor(totalWorkedMinutes / 60);
      const minutes = totalWorkedMinutes % 60;
      return `${hours}h ${minutes}m`;
    } else if (type === "monthly") {
      // Monthly calculation (from 1st to last day of month)
      const { startOfMonth, endOfMonth } = getMonthRange(currentDay);

      const monthAttendance = Object.entries(user.attendance).filter(
        ([date]) =>
          new Date(date) >= startOfMonth && new Date(date) <= endOfMonth
      );

      const totalWorkedMinutes = monthAttendance.reduce(
        (total, [, attendance]) =>
          total +
          (attendance.checkInTime && attendance.endTime
            ? calculateWorkedMinutes(attendance)
            : 0),
        0
      );

      const hours = Math.floor(totalWorkedMinutes / 60);
      const minutes = totalWorkedMinutes % 60;
      return `${hours}h ${minutes}m`;
    }

    return "-";
  };

  const handleRestartDay = (userId) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId
          ? {
              ...user,
              attendance: {
                ...user.attendance,
                [formatDate(currentDay)]: undefined, // Remove the current day's attendance
              },
            }
          : user
      )
    );
  };
  // Function to get the start and end of the week based on the current date
  const getWeekRange = (date) => {
    const startOfWeek = new Date(date);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Start from Sunday

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6); // End on Saturday

    const formatDate = (d) =>
      d.toLocaleDateString("en-US", {
        month: "numeric",
        day: "numeric",
      });

    return `${formatDate(startOfWeek)} to ${formatDate(endOfWeek)}`;
  };
  const formatMinutes = (value) => {
    return value < 10 ? `0${value}` : value;
  };
  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Attendance Tool</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <Button onClick={testUsers}>Test</Button> */}
        {/* View Selectors (Daily, Weekly, Monthly) */}
        <div
          className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-3"
          style={{ marginTop: "3%" }}
        >
          <Button
            className="mb-2 mb-md-0"
            variant={view === "daily" ? "primary" : "outline-primary"}
            onClick={() => handleViewChange("daily")}
          >
            Daily
          </Button>
          <Button
            className="mb-2 mb-md-0 mx-md-2"
            variant={view === "weekly" ? "primary" : "outline-primary"}
            onClick={() => handleViewChange("weekly")}
          >
            Weekly
          </Button>
          <Button
            className="mb-2 mb-md-0"
            variant={view === "monthly" ? "primary" : "outline-primary"}
            onClick={() => handleViewChange("monthly")}
          >
            Monthly
          </Button>
        </div>

        {/* Paging and Calendar Select */}
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-3">
          <Button className="mb-2 mb-md-0" onClick={() => handlePaging(-1)}>
            &lt; Prev
          </Button>
          <Form.Control
            type="date"
            value={formatDate(currentDay)}
            onChange={(e) => setCurrentDay(new Date(e.target.value))}
            className="mb-2 mb-md-0"
            style={{ width: "100%", maxWidth: "200px" }}
          />
          <Button className="mb-2 mb-md-0" onClick={() => handlePaging(1)}>
            Next &gt;
          </Button>
        </div>

        {/* Week and Month Summary Views */}
        {view === "weekly" && (
          <>
            <h6>Showing {getWeekRange(currentDay)}</h6>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Total On-Time (Hours)</th>
                  <th>Total Late (Hours)</th>
                  <th>Days Absent</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td data-label="User">{user.name}</td>
                    <td data-label="Total On-Time (Hours)">
                      {calculateTotalTime(user, view)}
                    </td>
                    <td data-label="Total Late (Hours)">{user.minutesLate}</td>
                    <td data-label="Days Absent">
                      {user.attendance[formatDate(currentDay)]?.status ===
                      "absent"
                        ? 1
                        : 0}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
        {view === "monthly" && (
          <>
            <h6>
              Showing{" "}
              {new Date(currentDay).toLocaleString("default", {
                month: "long",
              })}
            </h6>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Total On-Time (Hours)</th>
                  <th>Total Late (Hours)</th>
                  <th>Days Absent</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td data-label="User">{user.name}</td>
                    <td data-label="Total On-Time (Hours)">
                      {calculateTotalTime(user, view)}
                    </td>
                    <td data-label="Total Late (Hours)">{user.minutesLate}</td>
                    <td data-label="Days Absent">
                      {user.attendance[formatDate(currentDay)]?.status ===
                      "absent"
                        ? 1
                        : 0}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}

        {/* Daily View */}
        {view === "daily" && (
          <>
            <div className="attendance-control-box">
              {/* Daily Start Time Selectors */}
              <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-3">
                <label className="mb-2 mb-md-0">Daily Start Time: </label>

                <div className="d-flex flex-row justify-content-start align-items-center mb-2 mb-md-0">
                  <Form.Control
                    as="select"
                    value={dailyStartTime.hour}
                    onChange={(e) =>
                      setDailyStartTime({
                        ...dailyStartTime,
                        hour: e.target.value,
                      })
                    }
                    className="me-2"
                    style={{ width: "80px" }}
                  >
                    {[...Array(24).keys()]
                      .filter((t) => t !== 0)
                      .map((hour) => (
                        <option key={hour} value={hour}>
                          {hour}
                        </option>
                      ))}
                  </Form.Control>

                  <Form.Control
                    as="select"
                    value={dailyStartTime.minute}
                    onChange={(e) =>
                      setDailyStartTime({
                        ...dailyStartTime,
                        minute: e.target.value,
                      })
                    }
                    className="me-2"
                    style={{ width: "80px" }}
                  >
                    {[...Array(60).keys()].map((minute) => (
                      <option key={minute} value={minute}>
                        {formatMinutes(minute)}
                      </option>
                    ))}
                  </Form.Control>

                  <Form.Control
                    as="select"
                    value={timeZone}
                    onChange={(e) => setTimeZone(e.target.value)}
                    style={{ width: "100px" }}
                  >
                    <option value="PST">PST</option>
                    <option value="CST">CST</option>
                    <option value="EST">EST</option>
                  </Form.Control>
                </div>
              </div>

              {/* Training Length */}
              <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-3">
                <label className="mb-2 mb-md-0">
                  Training Length (minutes):{" "}
                </label>

                <Form.Control
                  type="number"
                  value={trainingLength}
                  onChange={(e) => setTrainingLength(Number(e.target.value))}
                  style={{ width: "100px" }}
                />
              </div>
            </div>

            {/* Users List */}
            {users.map((user) => (
              <div
                key={user.id}
                className="mb-4 p-3 attendance-user-tile"
                style={{
                  color: "#FFFFFF",
                  borderRadius: "10px",
                }}
              >
                <h5>{user.name}</h5>
                {new Date() >
                new Date(currentDay).setHours(
                  dailyStartTime.hour,
                  dailyStartTime.minute,
                  0,
                  0
                ) ? (
                  <div>
                    {(!user.attendance[formatDate(currentDay)]?.endTime ||
                      user.attendance[formatDate(currentDay)]?.status ===
                        "onTime") && (
                      <Button
                        variant={
                          user.attendance[formatDate(currentDay)]?.status ===
                          "onTime"
                            ? "success"
                            : "outline-success"
                        }
                        size="sm"
                        onClick={() => handleCheckIn(user.id, "onTime")}
                        disabled={
                          !!user.attendance[formatDate(currentDay)]?.endTime
                        }
                      >
                        On-Time
                      </Button>
                    )}{" "}
                    {(!user.attendance[formatDate(currentDay)]?.endTime ||
                      user.attendance[formatDate(currentDay)]?.status ===
                        "late") && (
                      <Button
                        variant={
                          user.attendance[formatDate(currentDay)]?.status ===
                          "late"
                            ? "warning"
                            : "outline-warning"
                        }
                        size="sm"
                        onClick={() => handleCheckIn(user.id, "late")}
                        disabled={
                          !!user.attendance[formatDate(currentDay)]?.endTime
                        }
                      >
                        Late
                      </Button>
                    )}{" "}
                    {(user.attendance[formatDate(currentDay)]?.status ===
                      "absent" ||
                      !user.attendance[formatDate(currentDay)]?.endTime) && (
                      <Button
                        variant={
                          user.attendance[formatDate(currentDay)]?.status ===
                          "absent"
                            ? "danger"
                            : "outline-danger"
                        }
                        size="sm"
                        onClick={() => handleCheckIn(user.id, "absent")}
                        disabled={
                          user.attendance[formatDate(currentDay)]?.endTime
                        }
                      >
                        Absent
                      </Button>
                    )}{" "}
                    {user.attendance[formatDate(currentDay)]?.status ===
                      "late" &&
                      !user.attendance[formatDate(currentDay)]?.endTime && (
                        <Form.Control
                          type="number"
                          placeholder="Enter minutes late"
                          onBlur={(e) =>
                            handleLateTimeChange(
                              user.id,
                              parseInt(e.target.value, 10)
                            )
                          }
                          style={{
                            width: "300px",
                            display: "inline-block",
                            margin: "10px",
                          }}
                        />
                      )}
                  </div>
                ) : (
                  <div>Today's training has not started.</div>
                )}

                {/* End Day, Delete Entry */}
                {
                  <div className="mt-2">
                    {user.attendance[formatDate(currentDay)]?.status !==
                      undefined &&
                      new Date() >
                        new Date(currentDay).setHours(
                          dailyStartTime.hour,
                          dailyStartTime.minute,
                          0,
                          0
                        ) && (
                        <>
                          {!user.attendance[formatDate(currentDay)]?.endTime ? (
                            <Button
                              variant="secondary"
                              size="sm"
                              onClick={() => handleEndDay(user.id)}
                              disabled={
                                !!user.attendance[formatDate(currentDay)]
                                  ?.endTime
                              }
                            >
                              Clock-Out
                            </Button>
                          ) : (
                            <Button
                              variant="secondary"
                              size="sm"
                              onClick={() => handleRestartDay(user.id)}
                              disabled={
                                !user.attendance[formatDate(currentDay)]
                                  ?.endTime
                              }
                            >
                              Undo Record
                            </Button>
                          )}
                        </>
                      )}{" "}
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => setUserToDelete(user.id)}
                    >
                      Delete User
                    </Button>
                  </div>
                }
                {userToDelete && (
                  <Modal
                    show={true}
                    onHide={() => setUserToDelete(null)}
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Confirm Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        Deleting this user will delete all attendance entries,
                        are you sure you wish to delete this user?
                      </p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => setUserToDelete(null)}
                      >
                        Cancel
                      </Button>
                      <Button variant="danger" onClick={handleDeleteUser}>
                        Delete
                      </Button>
                    </Modal.Footer>
                  </Modal>
                )}

                {/* Extra time and total time */}

                <div className="mt-3">
                  {!user.attendance[formatDate(currentDay)]?.endTime &&
                    user.attendance[formatDate(currentDay)]?.checkInTime && (
                      <Button
                        style={{
                          backgroundColor: "#800080",
                          color: "#FFFFFF",
                          border: "none",
                        }}
                        size="sm"
                        onClick={() => setAddingExtraTime(user.id)}
                      >
                        Add Time
                      </Button>
                    )}
                  {addingExtraTime === user.id &&
                    user.attendance[formatDate(currentDay)]?.checkInTime && (
                      <div className="mt-2">
                        <Form.Control
                          type="number"
                          value={extraTimeValue}
                          onChange={(e) =>
                            setExtraTimeValue(parseInt(e.target.value))
                          }
                          placeholder="Minutes"
                        />
                        <Button
                          variant="info"
                          className="mt-2"
                          style={{
                            backgroundColor: "#800080",
                            color: "#FFFFFF",
                            border: "none",
                          }}
                          onClick={() => handleAddTime(user.id)}
                        >
                          Save
                        </Button>{" "}
                        {addingExtraTime !== null && (
                          <Button
                            variant="warning"
                            className="mt-2"
                            style={{
                              border: "none",
                            }}
                            onClick={() => setAddingExtraTime(null)}
                          >
                            Cancel
                          </Button>
                        )}
                      </div>
                    )}
                  <p style={{ marginTop: "3%" }}>
                    {" "}
                    {user.attendance[formatDate(currentDay)]?.endTime
                      ? `Clocked-Out at ${user.attendance[
                          formatDate(currentDay)
                        ]?.endTime.toLocaleString()}. Time calculated from ${
                          dailyStartTime?.hour
                        }:${
                          dailyStartTime?.minute > 9
                            ? dailyStartTime.minute
                            : "0" + dailyStartTime.minute
                        }${timeZone}: ${calculateTotalTime(user, view)}`
                      : `Time calculated from ${dailyStartTime?.hour}:${
                          dailyStartTime?.minute > 9
                            ? dailyStartTime.minute
                            : "0" + dailyStartTime.minute
                        }${timeZone}: ${calculateTotalTime(user, view)}`}{" "}
                    {user.attendance[formatDate(currentDay)]?.extraMinutes >
                      0 &&
                      ` Plus added time of ${
                        user.attendance[formatDate(currentDay)]?.extraMinutes
                      } minutes.`}
                    {user.attendance[formatDate(currentDay)]?.minutesLate > 0 &&
                      ` Subtracting late time of ${
                        user.attendance[formatDate(currentDay)]?.minutesLate
                      } minutes.`}
                  </p>
                </div>
              </div>
            ))}

            {/* Add New User Section */}
            {!showAddUserField && (
              <Button
                variant="primary"
                className="mt-3"
                style={{ marginLeft: "7px", marginBottom: "1.5rem" }}
                onClick={() => setShowAddUserField(true)}
              >
                Add User
              </Button>
            )}
            {showAddUserField && (
              <div className="mt-3">
                <Form.Control
                  type="text"
                  placeholder="Enter new user name"
                  value={newUserName}
                  onChange={(e) => setNewUserName(e.target.value)}
                />
                <Button
                  variant="success"
                  className="mt-2"
                  onClick={handleAddUser}
                >
                  Add
                </Button>{" "}
                <Button
                  variant="warning"
                  className="mt-2"
                  onClick={() => {
                    setShowAddUserField(false);
                    setNewUserName("");
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close Project
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AttendanceTool;
