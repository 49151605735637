import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Dropdown, DropdownButton } from "react-bootstrap";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Line } from "react-chartjs-2";
import "chart.js/auto";

// Mock Data for fallback
const mockData = {
  bitcoin: {
    7: [
      { time: "2024-09-18", price: 27000, high: 30000, low: 27000 },
      { time: "2024-09-19", price: 27500, high: 30000, low: 27000 },
      { time: "2024-09-20", price: 28000, high: 30000, low: 27000 },
      { time: "2024-09-21", price: 28500, high: 30000, low: 27000 },
      { time: "2024-09-22", price: 29000, high: 30000, low: 27000 },
      { time: "2024-09-23", price: 29500, high: 30000, low: 27000 },
      { time: "2024-09-24", price: 30000, high: 30000, low: 27000 },
    ],
    30: [
      { time: "2024-08-25", price: 26000, high: 30000, low: 26000 },
      { time: "2024-08-28", price: 26200, high: 30000, low: 26000 },
      { time: "2024-09-01", price: 27000, high: 30000, low: 26000 },
      { time: "2024-09-04", price: 27200, high: 30000, low: 26000 },
      { time: "2024-09-08", price: 28000, high: 30000, low: 26000 },
      { time: "2024-09-11", price: 28500, high: 30000, low: 26000 },
      { time: "2024-09-15", price: 29000, high: 30000, low: 26000 },
      { time: "2024-09-18", price: 29500, high: 30000, low: 26000 },
      { time: "2024-09-22", price: 30000, high: 30000, low: 26000 },
    ],
    365: [
      { time: "2023-09-24", price: 25000, high: 30000, low: 25000 },
      { time: "2023-11-24", price: 25500, high: 30000, low: 25000 },
      { time: "2024-01-24", price: 26000, high: 30000, low: 25000 },
      { time: "2024-03-24", price: 27000, high: 30000, low: 25000 },
      { time: "2024-05-24", price: 27500, high: 30000, low: 25000 },
      { time: "2024-07-24", price: 28000, high: 30000, low: 25000 },
      { time: "2024-09-24", price: 30000, high: 30000, low: 25000 },
    ],
  },
  ethereum: {
    7: [
      { time: "2024-09-18", price: 1800, high: 1920, low: 1800 },
      { time: "2024-09-19", price: 1820, high: 1920, low: 1800 },
      { time: "2024-09-20", price: 1840, high: 1920, low: 1800 },
      { time: "2024-09-21", price: 1860, high: 1920, low: 1800 },
      { time: "2024-09-22", price: 1880, high: 1920, low: 1800 },
      { time: "2024-09-23", price: 1900, high: 1920, low: 1800 },
      { time: "2024-09-24", price: 1920, high: 1920, low: 1800 },
    ],
    30: [
      { time: "2024-08-25", price: 1700, high: 1920, low: 1700 },
      { time: "2024-08-28", price: 1720, high: 1920, low: 1700 },
      { time: "2024-09-01", price: 1750, high: 1920, low: 1700 },
      { time: "2024-09-04", price: 1775, high: 1920, low: 1700 },
      { time: "2024-09-08", price: 1800, high: 1920, low: 1700 },
      { time: "2024-09-11", price: 1825, high: 1920, low: 1700 },
      { time: "2024-09-15", price: 1850, high: 1920, low: 1700 },
      { time: "2024-09-18", price: 1900, high: 1920, low: 1700 },
      { time: "2024-09-22", price: 1920, high: 1920, low: 1700 },
    ],
    365: [
      { time: "2023-09-24", price: 1500, high: 1920, low: 1500 },
      { time: "2023-11-24", price: 1550, high: 1920, low: 1500 },
      { time: "2024-01-24", price: 1600, high: 1920, low: 1500 },
      { time: "2024-03-24", price: 1700, high: 1920, low: 1500 },
      { time: "2024-05-24", price: 1750, high: 1920, low: 1500 },
      { time: "2024-07-24", price: 1800, high: 1920, low: 1500 },
      { time: "2024-09-24", price: 1920, high: 1920, low: 1500 },
    ],
  },
  litecoin: {
    7: [
      { time: "2024-09-18", price: 65, high: 72, low: 65 },
      { time: "2024-09-19", price: 67, high: 72, low: 65 },
      { time: "2024-09-20", price: 68, high: 72, low: 65 },
      { time: "2024-09-21", price: 69, high: 72, low: 65 },
      { time: "2024-09-22", price: 70, high: 72, low: 65 },
      { time: "2024-09-23", price: 71, high: 72, low: 65 },
      { time: "2024-09-24", price: 72, high: 72, low: 65 },
    ],
    30: [
      { time: "2024-08-25", price: 60, high: 72, low: 60 },
      { time: "2024-08-28", price: 61, high: 72, low: 60 },
      { time: "2024-09-01", price: 62, high: 72, low: 60 },
      { time: "2024-09-04", price: 63, high: 72, low: 60 },
      { time: "2024-09-08", price: 64, high: 72, low: 60 },
      { time: "2024-09-11", price: 65, high: 72, low: 60 },
      { time: "2024-09-15", price: 66, high: 72, low: 60 },
      { time: "2024-09-18", price: 70, high: 72, low: 60 },
      { time: "2024-09-22", price: 72, high: 72, low: 60 },
    ],
    365: [
      { time: "2023-09-24", price: 55, high: 72, low: 55 },
      { time: "2023-11-24", price: 57, high: 72, low: 55 },
      { time: "2024-01-24", price: 60, high: 72, low: 55 },
      { time: "2024-03-24", price: 63, high: 72, low: 55 },
      { time: "2024-05-24", price: 65, high: 72, low: 55 },
      { time: "2024-07-24", price: 68, high: 72, low: 55 },
      { time: "2024-09-24", price: 72, high: 72, low: 55 },
    ],
  },
};

// Cache to store fetched data
const cache = {
  bitcoin: { 7: null, 30: null, 365: null },
  ethereum: { 7: null, 30: null, 365: null },
  litecoin: { 7: null, 30: null, 365: null },
};

const CryptoTrackerModal = ({ show, onHide }) => {
  const [selectedCrypto, setSelectedCrypto] = useState("bitcoin");
  const [cryptoData, setCryptoData] = useState({});
  const [cryptoHistory, setCryptoHistory] = useState([]);
  const [timeRange, setTimeRange] = useState("7"); // Default to 7 days
  const [high, setHigh] = useState(null);
  const [low, setLow] = useState(null);

  const cryptos = [
    { id: "bitcoin", name: "Bitcoin" },
    { id: "ethereum", name: "Ethereum" },
    { id: "litecoin", name: "Litecoin" },
  ];

  const timeRanges = {
    7: "7 days",
    30: "30 days",
    365: "365 days",
  };

  const fetchCryptoData = useCallback(
    async (cryptoId, range) => {
      const testing = false;
      let high = 0,
        low = 0;
      if (cache[cryptoId][range]) {
        setCryptoHistory(cache[cryptoId][range]);
        high = Math.max(...cryptoHistory.map((point) => point.price));
        low = Math.min(...cryptoHistory.map((point) => point.price));
        setHigh(high);
        setLow(low);
        return;
      }
      if (!testing) {
        try {
          const res = await fetch(
            `https://api.coingecko.com/api/v3/coins/${cryptoId}`,
            {
              accept: "application/json",
              headers: {
                "x-cg-demo-api-key": `CG-izP5th66dSWpmZWnuPHkpfce`, // Assuming CoinGecko uses Bearer tokens; if not, replace 'Authorization' with the correct header.
              },
            }
          );
          const data = await res.json();
          setCryptoData({
            price: data.market_data.current_price.usd,
            symbol: data.symbol.toUpperCase(),
            name: data.name,
            high24h: data.market_data.high_24h.usd,
            low24h: data.market_data.low_24h.usd,
          });

          const historyRes = await fetch(
            `https://api.coingecko.com/api/v3/coins/${cryptoId}/market_chart?vs_currency=usd&days=${range}`,
            {
              accept: "application/json",
              headers: {
                "x-cg-demo-api-key": `CG-izP5th66dSWpmZWnuPHkpfce`, // Assuming CoinGecko uses Bearer tokens; if not, replace 'Authorization' with the correct header.
              },
            }
          );
          const historyData = await historyRes.json();
          const formattedData = historyData.prices.map(
            ([timestamp, price]) => ({
              time: new Date(timestamp).toLocaleDateString(),
              price,
            })
          );
          setCryptoHistory(formattedData);

          cache[cryptoId][range] = formattedData;
        } catch (err) {
          setCryptoHistory(mockData[cryptoId][range]);
          high = mockData[selectedCrypto][timeRange].reduce(
            (max, p) => (p.high > max ? p.high : max),
            0
          );
          low = mockData[selectedCrypto][timeRange].reduce(
            (min, p) => (p.low < min ? p.low : min),
            Infinity
          );
          setHigh(high);
          setLow(low);
        }
      } else {
        setCryptoHistory(mockData[cryptoId][range]);
        high = mockData[selectedCrypto][timeRange].reduce(
          (max, p) => (p.high > max ? p.high : max),
          0
        );
        low = mockData[selectedCrypto][timeRange].reduce(
          (min, p) => (p.low < min ? p.low : min),
          Infinity
        );
        setHigh(high);
        setLow(low);
      }
    },
    [selectedCrypto, timeRange, cryptoHistory]
  );

  useEffect(() => {
    if (show) {
      // Only run API call if modal is visible
      fetchCryptoData(selectedCrypto, timeRange);
    }
  }, [show, selectedCrypto, timeRange, fetchCryptoData]); // Depend on 'show' in addition to other dependencies

  const handleSelectCrypto = (id) => {
    setSelectedCrypto(id);
  };

  const handleTimeRangeChange = (range) => {
    setTimeRange(range);
  };

  const chartData = {
    labels: cryptoHistory.map((point) => point.time),
    datasets: [
      {
        label: `Price of ${selectedCrypto} (USD) - Last ${timeRanges[timeRange]}`,
        data: cryptoHistory.map((point) => point.price),
        borderColor: "#FF007F",
        fill: false,
      },
    ],
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      centered
      className="crypto-tracker-modal"
    >
      <Modal.Header closeButton>
        <DropdownButton
          id="dropdown-basic-button"
          title={`Tracking: ${
            cryptos.find((t) => t.id === selectedCrypto)?.name
          }`}
          onSelect={handleSelectCrypto}
        >
          {cryptos.map((crypto) => (
            <Dropdown.Item key={crypto.id} eventKey={crypto.id}>
              {crypto.name}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </Modal.Header>
      <Modal.Body>
        {
          <>
            <h4>
              {`${cryptos.find((t) => t.id === selectedCrypto)?.name} ${
                cryptoData?.symbol ?? ""
              }`}
            </h4>
            <p>
              {`Current Price: $${
                cryptoData.price !== undefined
                  ? cryptoData.price
                  : mockData[selectedCrypto]["7"].reduce((latest, current) => {
                      const latestDate = new Date(latest.time);
                      const currentDate = new Date(current.time);
                      return currentDate > latestDate ? current : latest;
                    }).price
              }`}
            </p>
            <p>
              {timeRange === "7"
                ? `7 Day High: $${high}`
                : timeRange === "30"
                ? `30 Day High: $${high}`
                : `Year High: $${high}`}
            </p>
            <p>
              {timeRange === "7"
                ? `7 Day Low: $${low}`
                : timeRange === "30"
                ? `30 Day Low: $${low}`
                : `Year Low: $${low}`}
            </p>

            <h5>Price Movement Over Last {timeRanges[timeRange]}</h5>
            <div
              className="time-range-buttons"
              style={{ marginBottom: "20px" }}
            >
              {Object.keys(timeRanges).map((range) => (
                <Button
                  key={range}
                  variant={timeRange === range ? "primary" : "outline-primary"}
                  onClick={() => handleTimeRangeChange(range)}
                  style={{ marginRight: "10px" }}
                >
                  {timeRanges[range]}
                </Button>
              ))}
            </div>
            <Line data={chartData} style={{ padding: "1%" }} />

            <h5 style={{ marginTop: "5%" }}>API Request Example</h5>
            <SyntaxHighlighter language="javascript" style={dracula}>
              {`
fetch('https://api.coingecko.com/api/v3/coins/${selectedCrypto}/market_chart?vs_currency=usd&days=${timeRange}')
  .then(response => response.json())
  .then(data => console.log(data));
              `}
            </SyntaxHighlighter>
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close Project
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CryptoTrackerModal;
